import _objectWithoutProperties from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["name", "society", "text", "imgUrl", "blob", "href", "nativeLazyLoad"];
var __jsx = React.createElement;
import * as React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import * as ds from '@bob/design-system';
import { styles as S } from '@bob/design-system';

function TestimonialComponent(_ref) {
  var name = _ref.name,
      society = _ref.society,
      text = _ref.text,
      imgUrl = _ref.imgUrl,
      blob = _ref.blob,
      href = _ref.href,
      _ref$nativeLazyLoad = _ref.nativeLazyLoad,
      nativeLazyLoad = _ref$nativeLazyLoad === void 0 ? false : _ref$nativeLazyLoad,
      rest = _objectWithoutProperties(_ref, _excluded);

  return __jsx(Link, {
    prefetch: false,
    href: href,
    passHref: true
  }, __jsx(Wrapper, rest, __jsx(ds.BlobPicture, {
    nativeLazyLoad: nativeLazyLoad,
    src: imgUrl,
    variant: blob.variant,
    color: blob.color,
    size: 300
  }), __jsx(Body, null, __jsx(S.heading.Title4, null, name), __jsx(SubTitle, null, society), __jsx(Text, null, text), __jsx(ds.LinkButtonAsFake, null, "D\xE9couvrir"))));
}

export var Wrapper = styled.a.withConfig({
  displayName: "Testimonial__Wrapper",
  componentId: "sc-1nv33b7-0"
})(["text-align:center;@media screen and (", "){max-width:370px;}"], ds.breakpoint.MOBILE_L);
export var Body = styled.div.withConfig({
  displayName: "Testimonial__Body",
  componentId: "sc-1nv33b7-1"
})(["padding:20px 20px 0;text-align:left;"]);
export var SubTitle = styled.div.withConfig({
  displayName: "Testimonial__SubTitle",
  componentId: "sc-1nv33b7-2"
})(["font-size:30px;font-family:", ";line-height:1;@media screen and (", "){font-size:35px;}"], ds.font.PRIMARY, ds.breakpoint.MOBILE_L);
export var Text = styled.p.withConfig({
  displayName: "Testimonial__Text",
  componentId: "sc-1nv33b7-3"
})(["margin:20px 0;"]);
export var Testimonial = styled(TestimonialComponent).withConfig({
  displayName: "Testimonial",
  componentId: "sc-1nv33b7-4"
})(["", ",", "{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}", "{transition:transform 0.3s ease-in-out;}", "{@media not screen and (", "){width:270px;height:270px;}}@media screen and (", "){&:hover{", "{&::before{transform:translateX(0);}svg{transform:translateX(5px);}}", "{transform:scale(1.1);}}}"], S.heading.Title4, SubTitle, S.picture.Image, ds.Picture, ds.breakpoint.MOBILE_L, ds.breakpoint.LAPTOP, S.linkButton.FakeLinkButton, S.picture.Image);