import React from "react";
var __jsx = React.createElement;
import styled, { css } from 'styled-components';
import * as ds from '@bob/design-system';
export function Controls(_ref) {
  var onClickLeft = _ref.onClickLeft,
      onClickRight = _ref.onClickRight;
  return __jsx(Wrapper, null, __jsx(ds.CircularButton, {
    outlined: true,
    onClick: onClickLeft
  }, __jsx(ds.icons.ChevronLeft, {
    size: 32
  })), __jsx(ds.CircularButton, {
    outlined: true,
    onClick: onClickRight
  }, __jsx(ds.icons.ChevronRight, {
    size: 32
  })), __jsx(HalfTransparentBlock, {
    position: "left"
  }), __jsx(HalfTransparentBlock, {
    position: "right"
  }));
}
var Wrapper = styled.div.withConfig({
  displayName: "Controls__Wrapper",
  componentId: "sc-1rkdylq-0"
})(["", "{position:absolute;top:45%;z-index:2;@media not screen and (", "){width:40px;height:40px;min-height:40px;top:55%;", "{width:24px;height:24px;line-height:24px;}}}", ":nth-child(1){left:3%;}", ":nth-child(2){right:3%;}@media screen and (", "){", ":nth-child(1){left:1%;}", ":nth-child(2){right:1%;}}"], ds.CircularButton, ds.breakpoint.TABLET, ds.icons.Icon, ds.CircularButton, ds.CircularButton, ds.breakpoint.TABLET, ds.CircularButton, ds.CircularButton);
export var HalfTransparentBlock = styled.div.withConfig({
  displayName: "Controls__HalfTransparentBlock",
  componentId: "sc-1rkdylq-1"
})(["height:100%;width:8%;position:absolute;top:0;z-index:1;pointer-events:none;", ""], function (_ref2) {
  var _ref2$color = _ref2.color,
      color = _ref2$color === void 0 ? ds.color.PINK : _ref2$color,
      position = _ref2.position;
  var rgb = '';
  var pinkRgb = "255, 197, 170";
  var yellowRgb = "251, 218, 101";

  if (color === ds.color.PINK) {
    rgb = pinkRgb;
  }

  if (color === ds.color.YELLOW) {
    rgb = yellowRgb;
  }

  if (position === 'left') {
    return css(["left:0;background:linear-gradient( 0.25turn,", ",", ",rgba(", ",0) );"], color, color, rgb);
  }

  if (position === 'right') {
    return css(["right:0;background:linear-gradient( 0.25turn,rgba(", ",0),", ",", " );"], rgb, color, color);
  }
});