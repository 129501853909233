import _objectWithoutProperties from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["children"];
var __jsx = React.createElement;
import * as React from 'react';
import styled from 'styled-components';
import * as ds from '@bob/design-system';
import * as S from './style';

function TestimonialsBigComponent(_ref) {
  var children = _ref.children,
      rest = _objectWithoutProperties(_ref, _excluded);

  return __jsx(ds.Carousel, rest, children.map(function (children, i) {
    return __jsx(S.Slide_spaced, {
      key: i
    }, children);
  }));
}

export var TestimonialsBig = styled(TestimonialsBigComponent).withConfig({
  displayName: "TestimonialsBig",
  componentId: "sc-50nqzn-0"
})(["@media not screen and (", "){display:none;}"], ds.breakpoint.LAPTOP);