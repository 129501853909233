import _extends from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["tag", "Component", "inView", "text", "brushes"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { styles as S } from '@bob/design-system';

function BrushTitleAnimatedComponent(_ref) {
  var _ref$tag = _ref.tag,
      tag = _ref$tag === void 0 ? 'h1' : _ref$tag,
      _ref$Component = _ref.Component,
      Component = _ref$Component === void 0 ? S.heading.Title1 : _ref$Component,
      _ref$inView = _ref.inView,
      inView = _ref$inView === void 0 ? false : _ref$inView,
      text = _ref.text,
      _ref$brushes = _ref.brushes,
      brushes = _ref$brushes === void 0 ? [] : _ref$brushes,
      rest = _objectWithoutProperties(_ref, _excluded);

  var words = text.split(' ');
  return __jsx(Component, _extends({
    as: tag,
    inView: inView
  }, rest), words.map(function (word, index) {
    var brush = brushes.find(function (brush) {
      return brush.wordIndex === index;
    });
    var key = "".concat(word, "-").concat(index);
    return brush ? __jsx(React.Fragment, {
      key: key
    }, __jsx(brush.comp, _extends({}, brush.props, {
      inView: inView,
      style: {
        '--word-index': index
      }
    }), word), ' ') : __jsx(React.Fragment, {
      key: key
    }, __jsx("span", {
      style: {
        '--word-index': index
      }
    }, word), ' ');
  }));
}

export var BrushTitleAnimated = styled(BrushTitleAnimatedComponent).withConfig({
  displayName: "BrushTitleAnimated",
  componentId: "sc-1mxgl0f-0"
})(["@keyframes write-in{from{clip-path:inset(-0.5em calc(100% + 1.5em) -0.5em -1.5em);}to{clip-path:inset(-0.5em -1.5em -0.5em -1.5em);}}& > span{animation:write-in 0.1s linear both;animation-play-state:", ";animation-delay:calc(0.1s * var(--word-index,0));}"], function (_ref2) {
  var _ref2$animated = _ref2.animated,
      animated = _ref2$animated === void 0 ? true : _ref2$animated,
      inView = _ref2.inView;
  return inView && animated ? 'running' : 'paused';
});