import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as brush from '../Brush'

const SYMBOL_ID = 'brush-5-def'

FullBrush5.filename = SYMBOL_ID
export function FullBrush5(props: brush.SpecificBrushProps): React.ReactElement {
    return (
        <brush.Brush {...props} viewBox="0 0 133 39" name="brush-5">
            <defs>
                <path
                    id={SYMBOL_ID}
                    d="M110.712 4.03177C102.91 2.82157 107.182 2.40599 109.011 0.321088C110.078 -0.895646 28.0999 2.21933 18.9479 3.54913C12.4178 4.49797 6.23792 5.97825 4.82539 6.59343C1.43319 8.07077 -2.79024 11.7452 3.23804 11.2286C9.26632 10.7121 11.1312 13.5111 2.85751 16.0988C-5.41613 18.6866 9.21611 17.9766 4.72276 19.8943C0.229413 21.812 7.82409 24.1277 13.7344 24.5607C19.6447 24.9936 14.3042 28.0315 8.21568 27.9105C3.46672 27.6747 18.9081 31.4841 18.9081 31.4841C18.9081 31.4841 19.3419 33.3257 14.4291 33.0051C9.51631 32.6844 13.5575 38.6172 17.9787 38.6833C22.4 38.7494 104.272 34.2484 107.457 33.8766C110.643 33.5048 97.2439 32.428 102.214 31.1616C107.185 29.8952 116.254 32.9745 110.847 30.1747L107.57 28.4778C107.57 28.4778 120.642 30.2278 121.964 28.2851C123.287 26.3424 120.153 27.4155 116.885 24.8121C115.158 23.4362 128.042 25.8293 128.605 24.1C129.169 22.3707 119.547 22.7546 116.373 20.505C113.2 18.2554 123.955 19.8969 128.764 20.7703C133.573 21.6437 132.231 19.5338 128.121 17.8095C124.01 16.0852 110.142 12.8317 117.809 13.1636C125.475 13.4955 131.616 12.5902 133.028 10.4915C134.44 8.39281 108.938 8.30928 131.861 5.84512C137.104 5.2816 118.514 5.24198 110.712 4.03177Z"
                />
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </brush.Brush>
    )
}

export const Brush5 = styled(function Brush5(
    props: brush.SpecificBrushProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <brush.Brush {...props} viewBox="0 0 133 39" name="brush-5">
            <use href={href} />
        </brush.Brush>
    )
})``
