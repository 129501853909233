import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as brush from '../Brush'

const SYMBOL_ID = 'brush-2-def'

FullBrush2.filename = SYMBOL_ID
export function FullBrush2(props: brush.SpecificBrushProps): React.ReactElement {
    return (
        <brush.Brush {...props} viewBox="0 0 274 82" name="brush-2">
            <defs>
                <path
                    id={SYMBOL_ID}
                    d="M238.763 1.482C243.798-2.57 45.514 8.094 4.094 17.787c-1.916.341-5.204 1.81-3.024 4.955 2.725 3.93 15.888.954 6.4 3.906S-.206 40.422 12.476 43.63c12.683 3.207 22.987 4.315 13.067 7.975-9.92 3.66 18.18 5.593 13.866 8.464-4.315 2.872 18.9 4.413 13.066 7.976-5.835 3.563 2.079 10.984 14.25 13.232 12.17 2.247 195.681-14.195 189.836-18.411-5.845-4.215 17.099 1.37 11.499-2.049-5.599-3.418-6.053-2.075 1.259-5.06 7.313-2.986-2.746-3.296-12.063-5.421-9.317-2.125 3.968-4.703 12.987-9.884 9.019-5.182-3.146-11.636-13.631-15.445-10.485-3.81-14.506-5.617-6.106-8.585 8.401-2.969 2.988-7.892-3.743-10.057-6.73-2.166-13.035-.832-8-4.883z"
                />
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </brush.Brush>
    )
}

export const Brush2 = styled(function Brush2(
    props: brush.SpecificBrushProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <brush.Brush {...props} viewBox="0 0 274 82" name="brush-2">
            <use href={href} />
        </brush.Brush>
    )
})``
