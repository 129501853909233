import _extends from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
import _slicedToArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray";
import _objectWithoutProperties from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["items", "nativeLazyLoad", "animated"];
import React from "react";
var __jsx = React.createElement;

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import styled, { css } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { styles as S } from '@bob/design-system';
import { blob, resetBlob } from '@bob/utils/blob';
import { TestimonialsBig } from './TestimonialsBig';
import { TestimonialsSmall } from './TestimonialsSmall';
import { Testimonial } from './Testimonial';

function TestimonialsComponent(_ref) {
  var items = _ref.items,
      _ref$nativeLazyLoad = _ref.nativeLazyLoad,
      nativeLazyLoad = _ref$nativeLazyLoad === void 0 ? false : _ref$nativeLazyLoad,
      _ref$animated = _ref.animated,
      animated = _ref$animated === void 0 ? true : _ref$animated,
      rest = _objectWithoutProperties(_ref, _excluded);

  resetBlob();

  var _useInView = useInView({
    triggerOnce: true,
    threshold: 0.35,
    skip: !animated
  }),
      _useInView2 = _slicedToArray(_useInView, 2),
      ref = _useInView2[0],
      inView = _useInView2[1];

  return __jsx(TestimonialsSection, _extends({}, rest, {
    ref: ref,
    animated: animated,
    inView: inView
  }), __jsx(TestimonialsSmall, null, items.map(function (item) {
    return __jsx(Testimonial, {
      nativeLazyLoad: nativeLazyLoad,
      key: item.title + item.subtitle + item.description,
      name: item.title,
      society: item.subtitle,
      href: item.link,
      imgUrl: item.image.src,
      text: item.description,
      blob: blob()
    });
  })), __jsx(TestimonialsBig, null, group(items, 2).map(function (page) {
    return page.map(function (item) {
      return __jsx(Testimonial, {
        nativeLazyLoad: nativeLazyLoad,
        key: item.title + item.subtitle + item.description,
        name: item.title,
        society: item.subtitle,
        href: item.link,
        imgUrl: item.image.src,
        text: item.description,
        blob: blob()
      });
    });
  })));
}

var TestimonialsSection = styled.section.withConfig({
  displayName: "testimonials__TestimonialsSection",
  componentId: "sc-1v63bpy-0"
})(["", ",", ",", ":nth-child(1),", ":nth-child(2){transition-property:opacity;transition-timing-function:ease-in-out;", "}", ",", "{transition-duration:400ms;}", ":nth-child(1),", ":nth-child(2){transition-duration:500ms;}", "{transition-delay:1000ms;}", "{transition-delay:1200ms;}", ":nth-child(1){transition-delay:500ms;}", ":nth-child(2){transition-delay:800ms;}"], S.carousel.ControlPrev, S.carousel.ControlNext, Testimonial, Testimonial, function (_ref2) {
  var _ref2$animated = _ref2.animated,
      animated = _ref2$animated === void 0 ? true : _ref2$animated,
      inView = _ref2.inView;

  if (!animated) {
    return css(["opacity:1;"]);
  }

  if (inView) {
    return css(["opacity:1;"]);
  } else {
    return css(["opacity:0;"]);
  }
}, S.carousel.ControlPrev, S.carousel.ControlNext, Testimonial, Testimonial, S.carousel.ControlPrev, S.carousel.ControlNext, Testimonial, Testimonial);
export var Testimonials = styled(TestimonialsComponent).withConfig({
  displayName: "testimonials__Testimonials",
  componentId: "sc-1v63bpy-1"
})(["", "{align-items:normal;}", ",", "{margin-top:247px;top:0;transform:none;}"], S.carousel.Wrapper, S.carousel.ControlPrev, S.carousel.ControlNext);

function group(array, by) {
  var grouped = [];
  var current = [];

  var _iterator = _createForOfIteratorHelper(array),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var item = _step.value;

      if (current.length === by) {
        grouped.push(current);
        current = [];
      }

      current.push(item);
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  if (current.length !== 0) {
    grouped.push(current);
  }

  return grouped;
}