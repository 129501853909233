import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'

import * as icons from '../svg/icons'

export type LinkButtonAsFakeProps = React.HTMLAttributes<HTMLDivElement>

function FakeLinkButtonComponent({
    children,
    ...rest
}: LinkButtonAsFakeProps): React.ReactElement {
    return (
        <S.linkButton.FakeLinkButton {...rest}>
            <S.linkButton.LinkText>{children}</S.linkButton.LinkText>
            <icons.ArrowRight />
        </S.linkButton.FakeLinkButton>
    )
}

export const LinkButtonAsFake = styled(FakeLinkButtonComponent)``
