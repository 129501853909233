import styled from 'styled-components'

import { useSvgHref } from '../../svgHrefContext'

import * as brush from '../Brush'

const SYMBOL_ID = 'brush-4-def'

FullBrush4.filename = SYMBOL_ID
export function FullBrush4(props: brush.SpecificBrushProps): React.ReactElement {
    return (
        <brush.Brush {...props} viewBox="0 0 138 37" name="brush-4">
            <defs>
                <path
                    id={SYMBOL_ID}
                    d="M135.545 4.13958C131.216 -2.19014 29.7364 0.742424 10.1215 8.69945C0.12687 11.8299 28.7794 9.6302 18.2981 13.1048C7.81678 16.5795 9.56635 18.783 16.4073 17.7134C23.2482 16.6438 13.3762 21.7948 6.49651 23.5679C-0.383155 25.341 25.6408 24.4551 14.4526 26.7193C3.26434 28.9834 29.4289 27.5545 9.30052 31.6373C-10.8279 35.7201 6.06288 37.7457 15.439 35.301C31.336 31.156 114.315 32.944 122.011 33.3279C126.718 33.5627 137.166 28.9834 124.153 28.7793C111.14 28.5752 130.656 24.6965 118.757 24.4652C106.858 24.2339 124.153 19.1846 129.834 18.6689C135.515 18.1531 142.303 14.8 132.157 14.3385C122.011 13.8769 137.304 11.4272 126.58 9.80613C115.856 8.18504 139.874 10.4693 135.545 4.13958Z"
                />
            </defs>
            <use href={`#${SYMBOL_ID}`} />
        </brush.Brush>
    )
}

export const Brush4 = styled(function Brush4(
    props: brush.SpecificBrushProps
): React.ReactElement {
    const href = useSvgHref(SYMBOL_ID)
    return (
        <brush.Brush {...props} viewBox="0 0 138 37" name="brush-4">
            <use href={href} />
        </brush.Brush>
    )
})``
