import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'
import { CircularButton } from './CircularButton'
import * as icons from '../svg/icons'

export type CarouselProps = React.HTMLAttributes<HTMLDivElement>

function CarouselComponent({ children, ...rest }: CarouselProps): React.ReactElement {
    const [count, setCount] = React.useState(0)
    const [animation, setAnimation] = React.useState('fade')
    const slides = React.Children.toArray(children)
    const slidesLength = slides.length
    return (
        <S.carousel.Wrapper {...rest}>
            <S.carousel.ControlPrev>
                <CircularButton
                    outlined
                    onClick={() => {
                        setAnimation('prev')
                        count === 0 ? setCount(slidesLength - 1) : setCount(count - 1)
                    }}
                >
                    <icons.ChevronLeft size={32} />
                </CircularButton>
            </S.carousel.ControlPrev>
            <S.carousel.Slide data-animation={animation}>
                {slides[count]}
            </S.carousel.Slide>
            <S.carousel.ControlNext>
                <CircularButton
                    outlined
                    onClick={() => {
                        setAnimation('next')
                        count === slidesLength - 1 ? setCount(0) : setCount(count + 1)
                    }}
                >
                    <icons.ChevronRight size={32} />
                </CircularButton>
            </S.carousel.ControlNext>
        </S.carousel.Wrapper>
    )
}

export const Carousel = styled(CarouselComponent)``
