import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as React from 'react';
import * as ds from '@bob/design-system';
export function useInfiniteCarousel(_ref) {
  var data = _ref.data,
      activeIndex = _ref.activeIndex,
      elementWidth = _ref.elementWidth,
      lengthMultiplier = _ref.lengthMultiplier,
      setActiveIndex = _ref.setActiveIndex;
  var ref = React.useRef(null);
  var isOnScrollDisabled = React.useRef(false);
  var scrollLeftRef = React.useRef(0);
  var settings = React.useRef();

  var _React$useState = React.useState(),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      fakeActiveIndex = _React$useState2[0],
      setFakeActiveIndex = _React$useState2[1];

  var usedLengthMultiplier = getUsedLengthMultiplier(lengthMultiplier, data.length);
  var isClientSide = typeof window !== 'undefined' && window.document && window.document.documentElement;
  var isMobile = isClientSide && ds.utils.browser.isMobile();
  var windowWidth = isClientSide && window.innerWidth;
  var scrollStep = isMobile ? windowWidth : elementWidth;
  var completeScrollStep = scrollStep && scrollStep * data.length;
  var renderedList = React.useMemo(function () {
    return isClientSide ? _toConsumableArray(Array(usedLengthMultiplier).fill(data).flatMap(function (list) {
      return list.map(function (item, i) {
        return _objectSpread(_objectSpread({}, item), {}, {
          realIndex: i
        });
      });
    })) : data;
  }, [data, usedLengthMultiplier, isClientSide]);
  React.useEffect(function () {
    var realInitActiveIndex = getInitIndex();
    var fakeInitActiveIndex = getFakeInitIndex(data.length, realInitActiveIndex, usedLengthMultiplier);

    var allSetsIndexes = _toConsumableArray(Array(usedLengthMultiplier)).map(function (_, index) {
      return _toConsumableArray(Array(data.length)).map(function (_, i) {
        return i + index * data.length;
      });
    });

    var leftIndexes = allSetsIndexes.slice(0, Math.floor(usedLengthMultiplier / 2)).flatMap(function (i) {
      return i;
    });
    var rightIndexes = allSetsIndexes.slice(Math.floor(usedLengthMultiplier / 2) + 1).flatMap(function (i) {
      return i;
    });
    var centerIndexes = allSetsIndexes.filter(function (list) {
      return list.includes(fakeInitActiveIndex);
    }).flatMap(function (i) {
      return i;
    });
    settings.current = {
      allSetsIndexes: allSetsIndexes,
      centerIndexes: centerIndexes,
      leftIndexes: leftIndexes,
      rightIndexes: rightIndexes
    };

    if (windowWidth && scrollStep) {
      if (isMobile) {
        var mobileInitScrollLeft = fakeInitActiveIndex * scrollStep;
        scrollList(mobileInitScrollLeft);
        setActiveIndex(realInitActiveIndex);
        setFakeActiveIndex(fakeInitActiveIndex);
      } else {
        var midWindow = windowWidth / 2;
        var midLeftWindow = midWindow - scrollStep / 2;
        var activeScrollLeft = realInitActiveIndex * scrollStep;
        var diffForMid = activeScrollLeft - midLeftWindow;
        var stepsFromMiddle = Math.floor(usedLengthMultiplier / 2) * data.length * scrollStep;
        var desktopInitScrollLeft = stepsFromMiddle + diffForMid;
        scrollList(desktopInitScrollLeft);
        setActiveIndex(realInitActiveIndex);
        setFakeActiveIndex(fakeInitActiveIndex);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [data]);
  var debouncedOnScroll = ds.hooks.useDebounce(function (e) {
    if (windowWidth && scrollStep && completeScrollStep && fakeActiveIndex !== undefined && activeIndex !== undefined) {
      var _settings$current, _settings$current2, _settings$current$all, _settings$current3;

      var isOutsideLeft = (_settings$current = settings.current) === null || _settings$current === void 0 ? void 0 : _settings$current.leftIndexes.includes(fakeActiveIndex);
      var isOutsideRight = (_settings$current2 = settings.current) === null || _settings$current2 === void 0 ? void 0 : _settings$current2.rightIndexes.includes(fakeActiveIndex);
      var currentScrollLeft = isMobile ? e.target.scrollLeft : scrollLeftRef.current;
      var targetListIndex = (_settings$current$all = (_settings$current3 = settings.current) === null || _settings$current3 === void 0 ? void 0 : _settings$current3.allSetsIndexes.findIndex(function (list) {
        return list.includes(fakeActiveIndex);
      })) !== null && _settings$current$all !== void 0 ? _settings$current$all : Math.floor(usedLengthMultiplier / 2);
      var fullStepsFromCenter = Math.abs(targetListIndex - Math.floor(usedLengthMultiplier / 2));

      if (isMobile) {
        var isCentered = currentScrollLeft % scrollStep === 0;

        if (isCentered) {
          if (isOutsideLeft) {
            var nextScrollStep = currentScrollLeft + completeScrollStep * fullStepsFromCenter;
            scrollList(nextScrollStep, 'auto');
            setFakeActiveIndex(fakeActiveIndex + data.length * fullStepsFromCenter);
          }

          if (isOutsideRight) {
            var _nextScrollStep = currentScrollLeft - completeScrollStep * fullStepsFromCenter;

            scrollList(_nextScrollStep, 'auto');
            setFakeActiveIndex(fakeActiveIndex - data.length * fullStepsFromCenter);
          }
        }
      } else {
        if (isOutsideLeft) {
          var _nextScrollStep2 = currentScrollLeft + completeScrollStep * fullStepsFromCenter;

          scrollList(_nextScrollStep2, 'auto');
          setFakeActiveIndex(fakeActiveIndex + data.length * fullStepsFromCenter);
        }

        if (isOutsideRight) {
          var _nextScrollStep3 = currentScrollLeft - completeScrollStep * fullStepsFromCenter;

          scrollList(_nextScrollStep3, 'auto');
          setFakeActiveIndex(fakeActiveIndex - data.length * fullStepsFromCenter);
        }
      }
    }
  }, false, 50);
  return {
    isMobile: isMobile,
    ref: ref,
    renderedList: renderedList,
    onClickElement: onClickElement,
    onScroll: onScroll,
    onScrollOneStepLeft: onScrollOneStepLeft,
    onScrollOneStepRight: onScrollOneStepRight
  };

  function scrollList(left, behavior) {
    var _ref$current;

    scrollLeftRef.current = left;
    (_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.scrollTo({
      left: left,
      behavior: behavior
    });
  }

  function onClickElement(fakeIndex, realIndex) {
    if (!isMobile) {
      if (fakeActiveIndex !== undefined && scrollStep) {
        if (fakeIndex !== fakeActiveIndex) {
          var nextScrollStep = scrollLeftRef.current - (fakeActiveIndex - fakeIndex) * scrollStep;
          scrollList(nextScrollStep, 'smooth');
          setActiveIndex(realIndex);
          setFakeActiveIndex(fakeIndex);
        }
      }
    }
  }

  function onScroll(e) {
    if (isMobile) {
      onMobileScroll(e.target.scrollLeft);
    }

    debouncedOnScroll(e);
  }

  function onMobileScroll(scrollLeft) {
    if (activeIndex !== undefined && fakeActiveIndex !== undefined && scrollStep) {
      var step = scrollLeft / scrollStep;
      var roundedStep = Math.round(step);

      if (!isOnScrollDisabled.current) {
        if (roundedStep !== fakeActiveIndex) {
          setFakeActiveIndex(roundedStep);
          setActiveIndex(step - fakeActiveIndex > 0 ? activeIndex + 1 > data.length - 1 ? 0 : activeIndex + 1 : activeIndex - 1 < 0 ? data.length - 1 : activeIndex - 1);
        }
      } else {
        if (roundedStep === fakeActiveIndex) {
          isOnScrollDisabled.current = false;
        }
      }
    }
  }

  function onScrollOneStepLeft() {
    if (fakeActiveIndex !== undefined && activeIndex !== undefined && scrollStep) {
      if (isMobile) {
        isOnScrollDisabled.current = true;
        var nextScrollStep = fakeActiveIndex * scrollStep - scrollStep;
        scrollList(nextScrollStep, 'smooth');
      } else {
        var _nextScrollStep4 = scrollLeftRef.current - scrollStep;

        scrollList(_nextScrollStep4, 'smooth');
      }

      var nextIndex = activeIndex - 1 < 0 ? data.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
      setFakeActiveIndex(fakeActiveIndex - 1);
    }
  }

  function onScrollOneStepRight() {
    if (fakeActiveIndex !== undefined && activeIndex !== undefined && scrollStep) {
      if (isMobile) {
        isOnScrollDisabled.current = true;
        var nextScrollStep = fakeActiveIndex * scrollStep + scrollStep;
        scrollList(nextScrollStep, 'smooth');
      } else {
        var _nextScrollStep5 = scrollLeftRef.current + scrollStep;

        scrollList(_nextScrollStep5, 'smooth');
      }

      var nextIndex = activeIndex + 1 > data.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
      setFakeActiveIndex(fakeActiveIndex + 1);
    }
  }
}

function getInitIndex() {
  return 0;
}

function getFakeInitIndex(dataLength, realActiveIndex, usedLengthMultiplier) {
  return realActiveIndex + Math.floor(usedLengthMultiplier / 2) * dataLength;
}

function getUsedLengthMultiplier(lengthMultiplier, length) {
  if (lengthMultiplier) return lengthMultiplier;
  if (length < 4) return 14;
  if (length < 5) return 11;
  if (length < 6) return 8;
  if (length < 8) return 7;
  if (length < 10) return 6;
  return 5;
}