var __jsx = React.createElement;
import * as React from 'react';
import styled from 'styled-components';
import * as ds from '@bob/design-system';
var SIZE_IMAGE_ITEM = {
  height: 494,
  width: 338
};
export function RotativeCard(_ref) {
  var data = _ref.data,
      activeIndex = _ref.activeIndex;
  var rotation = useRotativeImage(activeIndex !== null && activeIndex !== void 0 ? activeIndex : 0);
  return __jsx(RotativeImage, {
    rotation: rotation
  }, data.map(function (item, i) {
    return __jsx(ds.Picture, {
      key: i,
      src: item.imgSrc,
      height: SIZE_IMAGE_ITEM.height,
      width: SIZE_IMAGE_ITEM.width,
      style: {
        opacity: activeIndex === i ? 1 : 0
      }
    });
  }));
}

function useRotativeImage(activeIndex) {
  var focusRef = React.useRef(activeIndex);
  var rotationRef = React.useRef(Math.random() * 9 - 4.5);

  if (focusRef.current !== activeIndex) {
    focusRef.current = activeIndex;
    rotationRef.current = Math.random() * 9 - 4.5;
  }

  return rotationRef.current;
}

var RotativeImage = styled.div.withConfig({
  displayName: "RotativeCard__RotativeImage",
  componentId: "sc-17k7shl-0"
})(["position:absolute;top:220px;left:50%;width:224px;transform:translate(-50%,-50%) rotate(", "deg);transition:transform 0.3s;border-radius:9px;overflow:hidden;z-index:1;&:before{content:'';display:block;padding-bottom:", "%;}&:after{background-color:#000;content:'';display:block;height:100%;left:0;opacity:0.2;position:absolute;top:0;width:100%;}", "{position:absolute;left:0;top:0;width:100%;height:100%;img{transition:opacity 0.2s;}}@media screen and (", "){width:280px;}@media screen and (", "){top:260px;}@media screen and (", "){width:318px;}"], function (_ref2) {
  var rotation = _ref2.rotation;
  return rotation;
}, SIZE_IMAGE_ITEM.height / SIZE_IMAGE_ITEM.width * 100, ds.Picture, ds.breakpoint.MOBILE_L, ds.breakpoint.TABLET, ds.breakpoint.LAPTOP);