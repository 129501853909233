import styled, { css, keyframes } from 'styled-components'

import * as breakpoint from '../constants/breakpoints'
import * as button from './button'
import * as icons from '../svg/icons'

export const Wrapper = styled.div`
    position: relative;
    @media screen and (${breakpoint.TABLET}) {
        display: grid;
        gap: 20px;
        align-items: center;
        grid-template-columns: 54px 1fr 54px;
    }
`

export const controls = css`
    @media not screen and (${breakpoint.TABLET}) {
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        ${button.CircularButton} {
            width: 40px;
            min-height: 40px;
            line-height: 40px;
            ${icons.Icon} {
                width: 24px;
                height: 24px;
                line-height: 24px;
            }
        }
    }
`

export const ControlPrev = styled.div`
    ${controls};
`

export const ControlNext = styled.div`
    ${controls};
    right: 0;
`

const fade = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

const next = keyframes`
    from {
        opacity: 0;
        transform: translate3d(150px, 0, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`

const prev = keyframes`
    from {
        opacity: 0;
        transform: translate3d(-150px, 0, 0)
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`

export const Slide = styled.div`
    & > * {
        animation-duration: 0.2s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }
    &[data-animation='fade'] > * {
        animation-name: ${fade};
    }
    &[data-animation='next'] > * {
        animation-name: ${next};
    }
    &[data-animation='prev'] > * {
        animation-name: ${prev};
    }
`
